<template>
  <div class="MonthlyBills">
    <div
      class="wrap"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="textbox">请录入人员业务关系</div>
      <div class="warningBox">
        <i class="el-icon-warning"></i>
        <div class="watiningtext">
          录入完成后，当日生效，如需回溯历史数据，请联系v_zzczhou(周智超)
        </div>
      </div>
      <div class="resetButton" @click="resetPage">
        <span>刷新</span>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="应用组名称">视频理解中台</el-table-column>
        <el-table-column prop="rtx" label="rtx"> </el-table-column>
        <el-table-column label="业务产品id"
          ><span class="redFont">待补齐</span></el-table-column
        >
        <el-table-column label="业务产品名称"
          ><span class="redFont">待补齐</span></el-table-column
        >
        <el-table-column label="业务产品路径"
          ><span class="redFont">待补齐</span></el-table-column
        >
        <el-table-column label="obs_id"
          ><span class="redFont">待补齐</span></el-table-column
        >
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="medium"
              @click="handleEdit(scope.$index, scope.row)"
              >维护人员信息</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="维护人员信息" :visible.sync="dailogShow">
        <el-form>
          <el-form-item label="应用组名称" :label-width="formLabelWidth">
            <span>视频理解中台</span>
          </el-form-item>
          <el-form-item label="rtx" :label-width="formLabelWidth">
            <span>{{ rtx_name }}</span>
          </el-form-item>
          <el-form-item
            label="业务产品id"
            :label-width="formLabelWidth"
            class="projectBox"
          >
            <el-input
              placeholder="请填写"
              style="width: 50%"
              v-model="input_proid"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="业务产品名称"
            :label-width="formLabelWidth"
            class="projectBox"
          >
            <el-input
              placeholder="请填写"
              style="width: 50%"
              v-model="input_proname"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="业务产品路径"
            :label-width="formLabelWidth"
            class="projectBox"
          >
            <el-input
              placeholder="请填写"
              style="width: 50%"
              v-model="input_propath"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="obs_id"
            :label-width="formLabelWidth"
            class="projectBox"
          >
            <el-input
              placeholder="请填写"
              style="width: 50%"
              v-model="input_obsid"
              clearable
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dailogShow = false">取 消</el-button>
          <el-button type="primary" @click="insertRtx">添加</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      user_name: [
        "linhuazhang",
        "lijianlin",
        "v_hlyou",
        "zhongangqi",
        "v_zzczhou",
        "v_vcqhchen",
      ],
      windowWidth: true,
      dailogShow: false,
      tableData: [],
      input_proid: "",
      input_proname: "",
      input_propath: "",
      input_obsid: "",
      formLabelWidth: "120px",
      rtx_name: "",
      loading: false,
      index: 0,
      url:"https://arc.tencent.com/video_understand_rtx/",
      // url: "http://11.151.219.86:8081/video_understand_rtx/",
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    handleEdit(index, row) {
      var isAllow = false;
      for (let i in this.user_name) {
        if (this.$route.fullPath.split("?user=")[1] == this.user_name[i]) {
          isAllow = true;
        }
      }
      if (isAllow) {
        this.dailogShow = true;
        this.rtx_name = row.rtx;
        this.index = index;
        this.input_proid = "";
        this.input_proname = "";
        this.input_propath = "";
        this.input_obsid = "";
      } else {
        this.$message({
          type: "error",
          message: "你无权对数据进行维护，如有需要请联系v_zzczhou(周智超)",
        });
      }
    },
    getData() {
      this.tableData = [];
      this.axios
        .get(this.url, {})
        .then((res) => {
          if (res.status == 200) {
            let arr = [];
            for (let key in res.data) {
              arr = res.data[key];
              for (let i = 0; i < arr.length; i++) {
                this.tableData.push({ rtx: arr[i] });
              }
            }
            this.$message({
              type: "success",
              message: "数据请求成功！",
            });
          } else {
            this.$message({
              type: "error",
              message: "数据请求失败！",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "数据请求失败！",
          });
        });
    },
    insertRtx() {
      if (this.input_proid.trim() == "") {
        this.$message({
          type: "warning",
          message: "请填写业务产品id",
        });
      } else if (this.input_proname.trim() == "") {
        this.$message({
          type: "warning",
          message: "请填写业务产品名称",
        });
      } else if (this.input_propath.trim() == "") {
        this.$message({
          type: "warning",
          message: "请填写业务产品路径",
        });
      } else if (this.input_obsid.trim() == "") {
        this.$message({
          type: "warning",
          message: "请填写obs_id",
        });
      } else {
        this.dailogShow = false;
        this.loading = true;
        var forData = new FormData();
        forData.append("rtx", this.rtx_name);
        forData.append("product_id", this.input_proid);
        forData.append("product_name", this.input_proname);
        forData.append("product_path", this.input_propath);
        forData.append("obs_id", this.input_obsid);
        let potss = forData;
        this.axios
          .post(this.url, potss)
          .then((res) => {
            if (res.data == "success!") {
              this.loading = false;
              this.$message({
                type: "success",
                message: "数据添加成功！",
              });
              this.tableData.splice(this.index, 1);
            } else {
              this.loading = false;
              this.$message({
                type: "error",
                message: "数据添加失败，请重新再试！",
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              type: "error",
              message: "数据添加失败，请重新再试！",
            });
          });
      }
    },
    resetPage() {
      location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.MonthlyBills {
  width: 100%;
  font-size: 0.16rem;
  .wrap {
    width: 100%;
    padding: 20px;
    .textbox {
      font-size: 24px;
      color: black;
    }
    .redFont {
      color: #ee6666;
    }
    .warningBox {
      width: 100%;
      height: 48px;
      background-color: #dfefff;
      color: #50565c;
      padding: 10px 0px;
      margin: 10px 0px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      i {
        margin: 0 10px 0 20px;
        color: #216adf;
      }
      .watiningtext {
        font-size: 14px;
      }
    }
    .resetButton {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 64px;
      height: 32px;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 3px;
      cursor: pointer;
      span {
        font-size: 14px;
        line-height: 32px;
        letter-spacing: 5px;
        color: black;
      }
    }
    .resetButton:hover {
      border: 1px solid #216adf;
      span {
        color: #216adf;
      }
    }
    .el-alert {
      margin: 20px 0;
      background-color: #dfefff;
      color: #595e63;
    }
    /deep/.el-form-item__label {
      text-align: left;
    }
    .el-select {
      width: 50%;
    }
    /deep/.el-dialog__header {
      padding: 20px 20px 20px 30px;
      border-bottom: 1px solid #ededed;
    }
    /deep/.el-dialog__footer {
      border-top: 1px solid #ededed;
    }
    .el-dialog__body {
      .el-form {
        margin-left: 10px;
      }
    }
    .el-form {
      .el-form-item {
        height: 20px;
      }
      .projectBox {
        margin-bottom: 36px;
      }
    }
  }
}
</style>